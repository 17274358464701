@import '~antd/lib/style/themes/default.less';
@import '~antd/dist/antd.less';
@font-face { 
  font-family:"ChronicleTextG1-Roman";
  src:url(fonts/ChronicleTextG1-Roman.8db5a2c1.eot) format("embedded-opentype"),url(fonts/ChronicleTextG1-Roman.9ace1544.otf) format("opentype"),url(fonts/ChronicleTextG1-Roman.d3bfc3da.woff) format("woff"),url(fonts/ChronicleTextG1-Roman.b27c95c9.ttf) format("truetype"),url(fonts/ChronicleTextG1-Roman.e4300b8d.svg) format("svg")
}
@white: #FFFFFF;
@black: #000000;
@crimson: #990033;
@light-grey: #fcfcfc;
@light-grey-border: #f0f0f0;
@dark-mode-text-color: #efefef;
@selection-color: #f9f6bd;
@blue-background: #474f5a;
html, body {
  font-size: 14px; //rem base
}
.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
//Body
.bg-image {
  background-color: @black;
  background-repeat: no-repeat;
  background-size: cover;
}
.bg-none {
  background-image: none !important;
}
//continuous bg image Privacy page
.body-general {
  height: 100vh;
  overflow-y: auto;
}
//Globals
::selection {
  color: @black;
  background-color: @selection-color;
}
a {
  -webkit-transition: opacity .2s ease-in;
  -o-transition: opacity .2s ease-in;
  transition: opacity .2s ease-in;
  &:hover {
    text-decoration: underline;
    opacity: .84;
  }
}
input {
  color: @black !important;
}
textarea {
  &:focus-visible {
    outline: 2px solid rgba(@black, 0.4);
  }
}
//Utilities
.margin-l {
  margin-left: 1rem;
}
.margin-r {
  margin-right: 1rem;
}
.margin-t {
  margin-top: 1rem;
}
.margin-b {
  margin-bottom: 1rem;
}
.padding-t {
  padding-top: 1rem;
}
.padding-b {
  padding-bottom: 0.5rem;
}
.border-top {
  border-top: 1px solid @light-grey-border;
}
.border-bottom {
  border-bottom: 1px solid @light-grey-border;
}
.lighter-text {
  opacity: 0.7; 
}
.small-line-height {
  line-height: 1;
}
.cmh5p-primary {
  background-color: rgba(@black, 0.8);
  color: @white;
}
.truncate {
  text-overflow: ellipsis;
}
//Buttons
.lg-icon {
  height: 2.3rem !important;
  width: 2.3rem !important;
}
.btn-back-to-top {
  bottom: 40px;
  right: 70px;
  .ant-back-top-content {
    background-color: @black;
    border: 2px solid @white;
  }
}
//
.ant-btn {
  min-width: 40px;
}
//Site Header
.site-header {
  margin: 0 0 0.5rem 0;
  padding: 1rem;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 5;
  background-color: @white;
  border-bottom: 1px solid rgba(@black,0.2);
}
.user-container {
  span {
    display: block;
  }
}
//Site header - item title
.item-title-container {
  border-top:1px solid rgba(@white, 0.2);
  background-color:@crimson;
  margin-bottom: 1rem;
  padding-bottom: 0.1rem;
  border-radius: 2px;
  h1, h2, h3 {
    margin: 0.7rem 1rem;
    font-size: 1.1em;
    color: @white;
  }
}
//Site Header - Actionbar
.actionbar {
  padding: 1rem;
  background-color: rgba(@white,0.8);
  color: @black;
  .actionbar-link {
    color: @black;
    border-top: 3px solid transparent;
    &:hover {
      border-top: 3px solid @black;
    }
  }
  .actionbar-link-active {
    border-top: 3px solid @black;
  }
  .actionbar-title {
    margin-bottom: 0;
  }
  .actionbar-controls {
    display: flex;
    align-items: center;
  }
}
//Logged in username and role
.user-name {
  display: block;
}
//Site Footer
.site-footer {
  margin-top: auto;
  padding: 1.5rem 1rem;
  color: @dark-mode-text-color;
  a {
    color: @dark-mode-text-color;
  }
}
//Page content
//invisible container needed for glass effect
.panel-body {
  margin-top: 6.5rem;
  background-color: rgba(@white,0.3);
  padding: 1rem;
}
//H5P container
.cm-h5p-container {
  background-color: @white;
}
.cm-h5p-inner-container {
  margin: 0 auto;
  padding: 1rem;
  background-color: @white;
}
//Loading Spinner
.loader-hidden {
  position: absolute; 
  right: 1rem;
  left: 1rem; 
  z-index: 0; //below H5P content
  background-color: @white;
}
//H5P content
.cm-h5p-content-container {
  position: relative;
  z-index: 1; //above the loader
  background-color: @white;
}
//Content item header - used above items in LMS
.content-item-header {
  background-color: @white;
}
//Message
.ant-message {
  .ant-message-notice {
    .ant-message-notice-content {
      color: @white;
      background: @black;
      padding: 16px 28px;
    }
  }
}
//Modals
.ant-modal-close-x {
  font-size: 30px;
  color: @white;
}
.ant-modal-header {
  border-radius: 0;
  background: @black;
  padding-top: 20px;
  padding-bottom: 20px;

  .ant-modal-title {
    color: @white;
  }
}
.ant-modal-content {
  border-radius: 0;
}
//List page table
.table-content-list {
  padding: 0 1rem;
  background-color: @white;
}
.ant-table-tbody {
  background-color:@white !important;
}
.ant-table-tbody > tr.ant-table-row-selected > td {
  background-color: rgba(@black, 0.13) !important;
}
.ant-table-tbody > tr.ant-table-row-selected {
  &:hover {
    background-color: rgba(@black, 0.13) !important;
  }
}
.ant-table-tbody > tr.ant-table-row:hover > td {
  background-color: rgba(@black, 0.08) !important;
}
.content-type-title {
  display: block;
}

//Login Page
.page-login {
  .left-panel {
    padding: 20rem 0 2rem 0;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: rgba(@black,0.6);
  }
  .right-panel {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-content: center;
    background: none;
  }
  .left-panel-header {
    align-self: center;

    h1 {
      margin: 0;
      color: @white;
      font-size: 4rem;
      font-weight: lighter;
      line-height: 3rem;
      font-family: "ChronicleTextG1-Roman";
      text-shadow: 0 0px 2px @black;
    }
    h2 {
      color:@white;
      font-size: 2.4rem;
      font-weight: lighter;
      line-height: 2.8rem;
      text-shadow: 0 0px 2px @black;
    }
  }
  .register-info {
    text-align: center;
    font-size: 12px;

    .register-info-button {
      font-size: 12px;
      padding: 0;
      margin: 0;
    }
  }
  .modal-register-info-contents {
    font-size: 0.95rem;
  }
  .login-container {
    width: 360px;
    padding: 3em;
    background-color: @light-grey;
    border: 1px solid rgba(@black,0.2);
  }
  .lti-login-container {
    width: 360px;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    padding: 3em;
    background-color: @light-grey;
    border: 1px solid rgba(@black,0.2);
  }
  .login-branding {
    margin-bottom: 1.5rem;
    text-align: center;
  }
  .fineprint {
    padding-top:0.5rem;
    text-align: center;
    font-size: 0.857rem //12px
  }
  .left-panel-footer {
    .footer-heading {
      color: @dark-mode-text-color;
      font-weight: lighter;
      font-family: "ChronicleTextG1-Roman";
      text-shadow: 0 0px 2px @black;
      font-size: 1.286rem; //18px
    }
    a {
      color: @dark-mode-text-color;
    }
    ul {
      padding: 0;
      margin: 0;
      list-style-type: none;
      li {
        padding:4px 0;
        text-shadow: 0 0px 2px @black;
        svg {
          color: @dark-mode-text-color;
          text-shadow: 0 0px 2px @black;
        }
      }
    }
  }
}
//PageStatistics
.statistics-container {
  margin-bottom: 1rem;

  .statistic-block {
    padding: 1rem;
    margin: 0 1rem 0 0;
    background-color: rgba(@black,0.75);
    color: @white;
    line-height: 1.3;
    display: flex;
    flex-direction: column;
    
    span {
      flex: 1;
      svg {
        margin-top:-3px;
      }
    }
    .statistic-title {
    }
    .statistic-result {
      font-size: 3.5em;
    }
  }
  .ant-row .ant-col:nth-last-child(1) .statistic-block {
    margin: 0;
  }
}
//Privacy policy page
.panel-body-container {
  padding: 3rem;
  background-color: rgba(@white,0.9);
  color: @black !important;
  h1 {
    font-size: 4rem;
    line-height: 3rem;
    font-family: "ChronicleTextG1-Roman";
  }
  h2 {
    font-size: 2rem;
  }
  h1,h2,h3,h4,h5,h6 {
    font-weight: lighter;
  }
}
.actionbar-transparent {
  margin-top: 3rem;
  padding: 1rem 3rem;
  background-color: rgba(@white,0.8);
  .actionbar-links {
    flex: 0 1 auto;
  }
}
//Error page
.error-container {
  padding: 3rem;
  background-color: @white;

  .container-error-message {
    display: flex;
    flex-direction: column;
  }
  .error-message {
    font-weight:lighter;
    font-size: 2rem;
    margin-top: 0;
    
  }
  .error-details {
    font-weight:lighter;
    font-size: 1.6rem;
  }
  .error-code {
    font-size: 1rem;
  }
  .icon-arrow-down img {
    margin-bottom: 1.5rem;
    display: block;
  }
  .error-action-button {
  }
}
//xs: '480px'
@media (max-width:480px) {
  //Login page
  #body-login .login-container {
    width: auto;
  }
  //Privacy page
  .panel-body-container {
    padding: 1.5rem;
  }
  .actionbar-transparent {
    padding: 1rem 1.5rem;
  }
  //Logo
  .branding-strap {
    height:28px;
  }
  //Error page
  .error-container {
    justify-content: center;
  }
  .error-action-button {
    width: 100%;
  }
}
//sm: '576px'
@media (max-width:576px) {
  .hide-on-mob {
    display: none;
  }
  .show-on-mob {
    display: inline;
  }
  .login-container {
    min-width: 300px;
    padding: 1em;
  }
  //Share modal
  .cm-tranfer {
    justify-content:center;
    .ant-transfer-list {
      margin: 0 !important;
      width:180px;
    }
  }
  //PageStatistics
  .statistics-container {
    .statistic-block {
      margin: 0 0 1rem 0;
    }
  }
  //site footer
  .site-footer {
    line-height: 2.5;
  }
}
@media (min-width:577px) {
  .hide-on-mob {
    display: inline;
  }
  .show-on-mob {
    display: none;
  }
}
//md: '768px'
@media (max-width:768px) {
  //List page
  .table-content-list {
    padding: 0;
  }
  //Page contents
  .panel-body {
    padding: 0;
  }
  //Modals
  .ant-modal {
    width: 95% !important;
    max-width: 95% !important;
  }
  //underline for table links on tab/mob
  .content-type-title {
    text-decoration: underline;
    &:active {
      color: @crimson;
    }
  }
}
//lg: '992px'
@media (max-width:991px) {
  .panel-body-login {
    top: 50%;
  }
  #login-footer-wrapper {
    display: none;
  }
}
//Blackboard UBN breakpoint
@media (min-width:1025px) {
  .site-header {
    padding: 1rem 3.65rem;
  }
}
//xlg: '1200px'
@media (min-width:1200px) {
}
//xxlg: 1500px
@media (min-width:1500px) {
  //h5p container
  .cm-h5p-inner-container {
    width: 960px; 
  }
}
//Watermark for all items
.h5p-player-container {
  position: relative;

  .watermark {
    position: absolute;
    z-index: 10000;
    top: 0px;
    right: 0px;
    font-size: 9px;
    color: rgba(@white, 0.8);
    user-select: none;
    padding: 2px 5px;
    background: rgba(@black, 0.2);
    border-radius: 4px;
    border-top-right-radius: 0;
    border-bottom-left-radius: 0;
  }
}

@primary-color: #990033;@link-color: #1e1e1e;